import React from "react"
import { css, jsx } from "@emotion/core"

import IndustryLayout from "../../components/layouts/industryLayout"

const IndustryIndexPage = props => (
  <IndustryLayout pageTitle="Membership Directory">
    <div>place holder</div>
  </IndustryLayout>
)

export default IndustryIndexPage
